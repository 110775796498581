import React from "react";
import Header from "./components/Header.js";
import "./App.css";
import cyndy from "./cyndy-finnigan.jpg";
import house from "./house.jpeg";

function App() {
  return (
    <div className="App">
      <Header />
      <img src={house} id="house-photo" alt="House" />
      <br />
      <span id="description">
        "Finding the best fit for you is my number one priority. I am dedicated
        to providing professional service and support throughout the home
        selling and buying process."
      </span>
      <img src={cyndy} id="cyndy-photo" alt="Cyndy Finnigan" />
      <div id="testimonial">
        "Cyndy was so transparent throughout the whole process, which lowered
        our anxiety levels greatly. When she didn't know the answer to a
        question, she made note and got back to us when she did. She was always
        available for tours, phone calls etc. It didn't take long for Cyndy to
        find out what we liked, which made the offer process that much easier.
        She was an absolute gem to work with and I would recommend her to my
        closest family and friends." - Laura E.
      </div>
    </div>
  );
}

export default App;
