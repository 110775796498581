import React from "react";
import logo from "./../keller-williams-seven-hills.png";
import phone from "./../phone-icon.png";
import email from "./../email-icon.png";

class Header extends React.Component {
  render() {
    return (
      <div id="main-header">
        <span id="left-header-content">
          <h1>Cyndy Finnigan</h1>
          <h3>Licensed Realtor</h3>
        </span>
        <span id="middle-header-content">
          <span className="info">
            <img src={phone} className="small-icon" alt="phone" />
            <span className="info-details">513-520-1520</span>
          </span>
          {/* <br /> */}
          <span className="info">
            <img
              src={email}
              id="email-icon"
              className="small-icon"
              alt="email"
            />
            <span className="info-details">cmfinnigan@kw.com</span>
          </span>
        </span>
        <img src={logo} className="keller-williams-logo" alt="logo" />
      </div>
    );
  }
}

export default Header;
